var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('menu.unitConversions'),"showSearchPanel":false},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('div')]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.unitConversionList,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoadingConversions,"items-per-page-select":{
                            label : 'Số lượng',
                            values : [10,20,50,100]
                            },"items-per-page":_vm.itemsPerPage,"pagination":""},on:{"pagination-change":_vm.onItemsPerPageChange},scopedSlots:_vm._u([(_vm.unitConversionList != null && _vm.unitConversionList.length > 0)?{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":8}},[_c('span',[_vm._v("Tổng số")]),_c('span',{staticStyle:{"padding-left":"5px","color":"crimson","font-weight":"bold"}},[_vm._v(_vm._s(_vm.unitConversionList == null ? 0: _vm.unitConversionList.length))]),_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v("mẩu tin")])])])])]},proxy:true}:null,{key:"fromUnit",fn:function(ref){
                            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.fromUnit.name)+" ")])]}},{key:"toUnit",fn:function(ref){
                            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.toUnit.name)+" ")])]}},{key:"product_price",fn:function(ref){
                            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.toUnit.ratio)+" ")])]}},{key:"user_detail_actions",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onEdit(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]),_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDelete(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}}],null,true)})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }