<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('menu.unitConversions')" :showSearchPanel="false">
                <template v-slot:searchBox>
                    <div></div>
                </template>

                <template v-slot:list>
                    <CDataTable :items="unitConversionList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoadingConversions"
                                :items-per-page-select="{
                                label : 'Số lượng',
                                values : [10,20,50,100]
                                }"
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                pagination>
                        <template #footer v-if="unitConversionList != null && unitConversionList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>Tổng số</span><span style="padding-left:5px;color:crimson;font-weight:bold">{{unitConversionList == null ? 0: unitConversionList.length}}</span><span style="padding-left:5px">mẩu tin</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>


                        <template #fromUnit="{item}">
                            <td>
                                {{item.fromUnit.name}}
                            </td>
                        </template>
                        <template #toUnit="{item}">
                            <td>
                                {{item.toUnit.name}}
                            </td>
                        </template>

                        <template #product_price="{item}">
                            <td>
                                {{item.toUnit.ratio}}
                            </td>
                        </template>

                        <template #user_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEdit(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDelete(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>
<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import { mapGetters, mapState, mapActions } from 'vuex'
    import ListForm from '@/components/ListForm.vue'

	export default {
        name: 'UnitConversionList',
		data() {            
            return {
                searchUnitFro: '',
                searchCategoryId: null,
                searchShowOnHomepage: null,
                searchMarkedAsNew: null,
                searchMarkedAsHot: null,
                searchPublished: null,
                searchIncludeSubCategories: false,

                isLoadingConversions: false,
				itemsPerPage: 10,
                warningModal: false,
                warningModalMessage: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod",
                fields: [
                    { key: 'fromUnit', label: i18n.t('common.fromUnit') },
                    { key: 'toUnit', label: i18n.t('common.toUnit') },
                    { key: 'ratio', label: i18n.t('common.ratio') },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
		},
        components: {
            Confirmation, ListForm
        },
        computed: {
            ...mapState('unit', ['unitConversionList']),     
        },
        methods: {
            ...mapActions('unit', ['getAllConversion','deleteConversion']),
			
            async onDelete(item) {
                this.$refs.confirmation.show(i18n.t('pages.product.confirmDelete'), async () => {//
                    console.log('Delete Unit Conversion', item);
                    this.deleteConversion({ id: item.id})
                });
            },
            onEdit(item) {
                this.$router.push('/units/conversions/edit/' + item.id);
            },

            onItemsPerPageChange(item) {
                localStorage.setItem('unitConversion-numItemsPerPage', item)
            }

		},
        async mounted() {
            let numItemsPerPage = parseInt(localStorage.getItem('unitConversion-numItemsPerPage'));
            if (isNaN(numItemsPerPage))
                numItemsPerPage = 10;
            this.itemsPerPage = numItemsPerPage;

            this.isLoadingConversions = true;
            await this.getAllConversion();
            this.isLoadingConversions = false;
        }
	}
</script>